import 'babel-polyfill'
import Vue from 'vue'
import router from './router'
import Utils from '@a/js/utils'
import querystring from 'querystring'
import store from './store'
import App from './App.vue'
// 请求拦截
import $http from '@api/http'

// 按需引入elementUI
import '@a/js/initElementUI'
import './assets/scss/main.scss'

// import './mock'
// vue bus
import VueBus from '@a/js/vueBus'
Vue.use(VueBus)
Vue.config.productionTip = false

const addMethod = {
    $utils: Utils,
    $qs: querystring,
    $http: $http,
    $bus: new Vue(), // 事件总线bus
    processEnv: process.env.NODE_ENV // 环境变量
}
Vue.prototype = Object.assign(Vue.prototype, addMethod)

router.beforeEach(async(to, from, next) => {
    // console.log('from, to', from, to)
    next()
})

new Vue({
    router,
    store,
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    },
    render: h => h(App)
}).$mount('#app')
