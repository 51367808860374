import Vue from 'vue'
import VueRouter from 'vue-router'

// 目前安装 3.1.1 以上的vue-router版本既可解决报错 npm i vue-router@3.1.1 -S
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ '@v/home/index.vue'),
        meta: {
            title: '首页'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '@v/login/index.vue'),
        meta: {
            title: '登录'
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import(/* webpackChunkName: "register" */ '@v/register/index.vue'),
        meta: {
            title: '注册'
        }
    },
    {
        path: '/password',
        name: 'Password',
        component: () => import(/* webpackChunkName: "password" */ '@v/password/index.vue'),
        meta: {
            title: '忘记密码'
        }
    },
    {
        path: '/news',
        name: 'News',
        // route level code-splitting
        // this generates a separate chunk (news.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "news" */ '@v/news/index.vue'),
        meta: {
            title: '资讯'
        }
    },
    {
        path: '/news/detail',
        name: 'NewsDetail',
        component: () => import(/* webpackChunkName: "news-detail" */ '@v/news/detail/index.vue'),
        meta: {
            title: '资讯详情'
        }
    },
    {
        path: '/product',
        name: 'Product',
        component: () => import(/* webpackChunkName: "product" */ '@v/product/index.vue'),
        meta: {
            title: '产品'
        }
    },
    {
        path: '/product/detail',
        name: 'ProductDetail',
        component: () => import(/* webpackChunkName: "product-detail" */ '@v/product/detail/index.vue'),
        meta: {
            title: '产品详情'
        }
    },
    {
        path: '/order',
        name: 'Order',
        component: () => import(/* webpackChunkName: "order" */ '@v/order/index.vue'),
        meta: {
            title: '订单'
        }
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () => import(/* webpackChunkName: "payment" */ '@v/payment/index.vue'),
        meta: {
            title: '支付'
        }
    },
    {
        path: '/payment-method',
        name: 'PaymentMethod',
        component: () => import(/* webpackChunkName: "payment" */ '@v/payment/payment-method.vue'),
        meta: {
            title: '支付方式'
        }
    },
    {
        path: '/payment-result',
        name: 'PaymentResult',
        component: () => import(/* webpackChunkName: "payment" */ '@v/payment/payment-result.vue'),
        meta: {
            title: '支付结果'
        }
    },
    {
        path: '/my/aftermarket-commit',
        name: 'AftermarketCommit',
        component: () => import(/* webpackChunkName: "my-aftermarket" */ '@v/my/aftermarket/commit/index.vue'),
        meta: {
            title: '售后信息提交'
        }
    },
    {
        path: '/my/aftermarket-success',
        name: 'AftermarketSuccess',
        component: () => import(/* webpackChunkName: "my-aftermarket" */ '@v/my/aftermarket/commit/success.vue'),
        meta: {
            title: '售后成功'
        }
    },
    {
        path: '/my/aftermarket-detail',
        name: 'AftermarketDetail',
        component: () => import(/* webpackChunkName: "my-aftermarket" */ '@v/my/aftermarket/detail/index.vue'),
        meta: {
            title: '售后详情'
        }
    },
    {
        path: '/my/order-detail',
        name: 'MyOrderDetail',
        component: () => import(/* webpackChunkName: "my-order" */ '@v/my/order/detail/index.vue'),
        meta: {
            title: '订单详情'
        }
    },
    {
        path: '/my/message',
        name: 'Message',
        component: () => import(/* webpackChunkName: "my-message" */ '@v/my/message/index.vue'),
        meta: {
            title: '消息'
        }
    },
    {
        path: '/my/password-reset',
        name: 'MyPasswordReset',
        component: () => import(/* webpackChunkName: "my-password-set" */ '@v/my/resetPassword/index.vue'),
        meta: {
            title: '密码重置'
        }
    },
    {
        path: '/my/aftermarket/information',
        name: 'AftermarketInformation',
        component: () => import(/* webpackChunkName: "my-aftermarket" */ '@v/my/aftermarket/information/index.vue'),
        meta: {
            title: '申请售后列表'
        }
    },
    {
        path: '/my/',
        name: 'My',
        component: () => import(/* webpackChunkName: "my" */ '@v/my/index.vue'),
        meta: {
            title: '个人中心'
        },
        children: [
            {
                path: '',
                name: 'Home',
                component: () => import(/* webpackChunkName: "my-home" */ '@v/my/home.vue'),
                meta: {
                    title: '个人中心'
                }
            },
            {
                path: 'order/:type',
                name: 'MyOrder',
                component: () => import(/* webpackChunkName: "my-order" */ '@v/my/order/index.vue'),
                meta: {
                    title: '我的订单'
                }
            },
            // {
            //     path: 'aftermarket/:type',
            //     name: 'MyAftermarket',
            //     component: () => import(/* webpackChunkName: "my-aftermarket" */ '@v/my/aftermarket/index.vue'),
            //     meta: {
            //         title: '售后申请'
            //     }
            // },
            {
                path: 'aftermarket/list',
                name: 'MyAftermarket',
                component: () => import(/* webpackChunkName: "my-aftermarket" */ '@v/my/aftermarket/index.vue'),
                meta: {
                    title: '我的售后列表'
                }
            },
            {
                path: 'address',
                name: 'MyAddress',
                component: () => import(/* webpackChunkName: "my-address" */ '@v/my/address/index.vue'),
                meta: {
                    title: '地址'
                }
            },
            {
                path: 'accout',
                name: 'MyAccout',
                component: () => import(/* webpackChunkName: "my-accout" */ '@v/my/accout/index.vue'),
                meta: {
                    title: '账户设置'
                }
            },
            {
                path: 'k-point',
                name: 'MyKPoint',
                component: () => import(/* webpackChunkName: "my-k-point" */ '@v/my/k-point/index.vue'),
                meta: {
                    title: '我的K点'
                }
            },
            {
                path: 'follow',
                name: 'MyFollow',
                component: () => import(/* webpackChunkName: "my-follow" */ '@v/my/follow/index.vue'),
                meta: {
                    title: '我的关注'
                }
            }
        ]
    }
]
const router = new VueRouter({
    // mode: 'history',
    routes
})
export default router
