// 处理http请求
import Utils from '@a/js/utils'
import Axios from 'axios'
import Qs from 'querystring'
import Router from '../router'
// import { Notification } from 'element-ui'
// import Vue from 'vue'
// import router from '../router'
const IS_PROD = ['production', 'test'].includes(process.env.NODE_ENV)
// 解决IE get请求缓存
// console.log('当前浏览器：', Utils.getBrowser());
const instance = Axios.create({
    // baseURL: IS_PROD ? '' : '/api',
    baseURL: '',
    timeout: 1000000
    // headers: { 'X-Custom-Header': 'foobar'
})

if (Utils.getBrowser() === 'IE') {
    instance.defaults.headers['no-cache'] = 'no-cache'
    instance.defaults.headers['Pragma'] = 'no-cache'
}

instance.interceptors.request.use(config => {
    if (config.method !== 'post' && config.data) {
        config.data = Qs.stringify(config.data)
    }
    // console.log('config', config)
    return config
}, error => {
    // console.log('error', error)
    return Promise.reject(error)
})

instance.interceptors.response.use(function (res) {
    // console.log('res', res)
    switch (res.status) {
        case (200 || 304):
            // 登录超时code
            // if (res.data.code !== 1001) {
            //     Utils.clearUser()
            //     location.href = '/'
            // } else {
            //     Notification({ title: '提示', type: res.data.detail.status === 1 ? 'success' : 'error', message: res.data.msg, position: 'bottom-right' });
            //     return res.data
            // }
            // 需要登录
            if (res.data.code === 1002) {
                // 清除登录 信息
                Utils.clearUser()
                console.log('Router', Router)
                // 判断是否需要登录，需要的话跳转登录页
                const toPath = Router.history.current.fullPath
                // 需要登录的页面（个人中心、订单、支付、支付方式）
                if (toPath.includes('/my') || toPath.includes('/order') || toPath.includes('/payment') || toPath.includes('/payment-method')) {
                    // 跳转登录页
                    Router.push(
                        {
                            path: '/login', query: { 'redirectUrl': toPath }
                        }
                    )
                }
            } else {
                // 请求成功
                // if (res.data.code === 1001) {
                return res.data
                // } else { // 请求失败
                // Notification({ title: '提示', type: 'error', message: res.data.msg, position: 'bottom-right' })
                // }
            }
            break
        default:
            return res.data
    }
}, function (error) {
    // console.log('error', error)
    if (error && error.response) {
        console.log('error && error.response', error)
        // Notification({ title: Vue.i18n.translate('提示'), type: 'error', message: Vue.i18n.translate(error.response.data.message), position: 'bottom-right' });
    } else {
        console.log('error', error)
        // Notification({ title: Vue.i18n.translate('提示'), type: 'error', message: Vue.i18n.translate('网络出现问题，请稍候重试'), position: 'bottom-right' })
    }
    return Promise.resolve(error.response)
})

export default instance
